import { Profile } from "../../../schema.interfaces";
import ThreadBox from "./ThreadBox";

export default function ThreadSection(
  threads: Profile["threads"],
  sectionTitle: string,
  selectedThreadId: string
) {
  return (
    <>
      {!!threads.length && (
        <div className="mb-3 flex flex-col gap-1">
          <p className="text-onChat mb-4">{sectionTitle}</p>
          {threads.map((thread) => (
            <ThreadBox
              key={thread._id}
              selected={selectedThreadId === thread._id}
              threadId={thread._id}
              threadTitle={thread.title}
              threadtool={thread.tool}
            />
          ))}
        </div>
      )}
    </>
  );
}
