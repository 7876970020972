import { create } from "zustand";

interface ModalState {
  isShowingModal: boolean;
  setIsShowingModal: (isShowingModal: boolean) => void;
  children: React.ReactNode;
  setChildren: (children: React.ReactNode) => void;
}

export const useModalStore = create<ModalState>()((set) => ({
  isShowingModal: false,
  setIsShowingModal: (isShowingModal) => set({ isShowingModal }),
  children: null,
  setChildren: (children) => set({ children }),
}));
