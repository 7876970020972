"use client";

import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button";

export default function NewThreadButton() {
  const navigate = useNavigate();

  return (
    <Button
      className="bg-slate-800 w-full"
      onClick={() => {
        navigate("/");
      }}
    >
      New Thread
    </Button>
  );
}
