"use client";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faFont,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { ModelRecord, models } from "../../../constants/supportedModels";
import { iconWidth } from "../../../constants/ui";

export default function LabCardModelPicker({
  model,
  onModelChange,
  validModelChoices,
}: {
  model: ModelRecord;
  onModelChange: (model: ModelRecord) => void;
  validModelChoices: ModelRecord[];
}) {
  const [isChoosing, setIsChoosing] = useState(false);
  const dropdownId = "MODELPICKER";

  // If the click is outside the dropdown, close the dropdown
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target instanceof HTMLElement) {
        if (e.target.closest("#" + dropdownId)) return;
      }
      setIsChoosing(false);
    });
  }, []);

  if (!model) return null;

  return (
    <div id={dropdownId} className=" w-fit">
      <ModelSelectedItem
        model={model}
        isChoosing={isChoosing}
        toggleIsChoosing={() => setIsChoosing(!isChoosing)}
      />
      {isChoosing && (
        <ModelDropdown
          validModelChoices={validModelChoices}
          currentModel={model}
          setIsChoosing={setIsChoosing}
          setModel={onModelChange}
        />
      )}
    </div>
  );
}

function ModelSelectedItem({
  model,
  isChoosing,
  toggleIsChoosing,
}: {
  model: ModelRecord;
  isChoosing: boolean;
  toggleIsChoosing: any;
}) {
  return (
    <div
      onClick={toggleIsChoosing}
      className={`flex gap-7 items-center hover:bg-lightgrey200 ${
        isChoosing && "bg-lightgrey200"
      } p-3 rounded-lg hover:cursor-pointer w-fit`}
    >
      <div
        style={{
          gridTemplateColumns: "repeat(2, minmax(min-content, max-content))",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "0.7rem",
        }}
        className="grid"
      >
        <FontAwesomeIcon
          className="w-fit"
          icon={model.supportsImage ? faImage : faFont}
          width={iconWidth}
        />
        <p className="text-lg font-bold  w-fit">{model.displayModel}</p>
        <p
          style={{ gridColumn: 2, gridRow: 2 }}
          className="text-sm font-normal w-fit"
        >
          {model.displayProvider}
        </p>
      </div>
      <FontAwesomeIcon icon={isChoosing ? faChevronUp : faChevronDown} />
    </div>
  );
}

function ModelDropdown({
  setIsChoosing,
  setModel,
  currentModel,
  validModelChoices,
}: {
  setIsChoosing: (isChoosing: boolean) => void;
  setModel: (model: ModelRecord) => void;
  currentModel: ModelRecord;
  validModelChoices: ModelRecord[];
}) {
  return (
    <div className="absolute bg-white shadow-md shadow-gray-500 w-60 mt-2 rounded-md z-30">
      {models.map((modelChoice) => (
        <ModelDropdownItem
          key={modelChoice.model}
          modelChoice={modelChoice}
          currentModel={currentModel}
          onClicked={() => {
            setIsChoosing(false);
            setModel(modelChoice);
          }}
          disabled={
            !validModelChoices.includes(modelChoice) &&
            modelChoice !== currentModel
          }
        />
      ))}
    </div>
  );
}

function ModelDropdownItem({
  onClicked,
  modelChoice,
  currentModel,
  disabled = false,
}: {
  currentModel: ModelRecord;
  onClicked: () => void;
  modelChoice: ModelRecord;
  disabled?: boolean;
}) {
  const background =
    currentModel.model === modelChoice.model ? "bg-lightgrey200" : "";
  const hoverStyle = disabled
    ? ""
    : "hover:bg-lightgrey200  hover:cursor-pointer";

  const iconColor = disabled ? "gray" : "black";
  const textColor = disabled ? "text-gray-500" : "text-black";

  return (
    <button
      key={modelChoice.model}
      onClick={onClicked}
      disabled={disabled}
      className={`${background} ${hoverStyle} p-3 flex flex-row gap-5 items-center w-full text-left`}
    >
      <FontAwesomeIcon
        icon={modelChoice.supportsImage ? faImage : faFont}
        size="sm"
        width={iconWidth}
        color={iconColor}
      />
      <div className="flex flex-col">
        <p className={"font-medium " + textColor}>{modelChoice.displayModel}</p>
        <p className={"text-sm " + textColor}>{modelChoice.displayProvider}</p>
      </div>
    </button>
  );
}
