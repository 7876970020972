"use client";

import { useEffect, useRef, useState } from "react";
import ChatSubmitButton from "./ChatSubmitButton";
import { protectedResources } from "../../../auth.config";
import useAutosizeTextArea from "../../../hooks/useAutoSizeTextArea";
import useFetchWithMsal from "../../../hooks/useFetchWithMsal";
import { useModelStore } from "../../../state/model";
import { useThreadsStore } from "../../../state/threads";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import ImagePreview from "./ImagePreview";
import ImageUploadButton from "./ImageUploadButton";
import { useProfileStore } from "../../../state/profile";
import { ToolRecord } from "../../../constants/supportedTools";
import { LLMChunk } from "../../../schema.interfaces";
import { JsonParseService } from "../../../services/jsonParse.service";
import { useChatInputSetterState } from "../../../state/chatInput";

export default function ChatInput({
  threadId,
  tool,
}: {
  threadId: string | null;
  tool?: ToolRecord;
}) {
  // Server requests
  const { execute: executeCreateNewThread } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );
  const { execute: executeChat } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );
  const { execute: executeGenerateThreadTitle } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );

  // Lifecycle state
  const navigate = useNavigate();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const model = useModelStore((state) => state.model);
  const [userInput, setUserInput] = useState<string>("");
  const [imageBase64, setImageBase64] = useState<string | null>(null); // image uploads

  // State setters
  const addThread = useThreadsStore((state) => state.addThread);
  const addThreadToProfile = useProfileStore(
    (state) => state.addThreadToProfile
  );

  const addMessageToThread = useThreadsStore(
    (state) => state.addMessageToThread
  );
  const addStreamedLLMChunkToThread = useThreadsStore(
    (state) => state.addStreamedLLMChunkToThread
  );

  const setNewThreadId = useThreadsStore(
    (state) => state.newThread.setNewThreadId
  );

  const updateThreadTitle = useThreadsStore((state) => state.updateThreadTitle);
  const updateThreadTitleInProfile = useProfileStore(
    (state) => state.updateThreadTitleInProfile
  );

  const updateThreadImageUsed = useThreadsStore(
    (state) => state.updateThreadImageUsed
  );

  // Used to set the function that can update the chat input - used by Saved Prompts
  const setChatInputSetter = useChatInputSetterState(
    (state) => state.setChatInputSetter
  );

  // Auto resize text area
  useAutosizeTextArea(textAreaRef.current, userInput);

  // Focus on text area when component mounts
  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  // Clear inputs if the user changes the model
  useEffect(() => {
    setUserInput("");
    setImageBase64(null);
  }, [model]);

  // Set chat input setter
  useEffect(() => {
    setChatInputSetter(setUserInput);
  }, [setChatInputSetter]);

  // Execute when a user sends a message
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userInput.trim() === "") return;

    // Record whether an image was used in this thread
    if (imageBase64) {
      updateThreadImageUsed(threadId!, true);
    }

    setLoading(true);

    let isCreatingNewThread = false;

    // Create a new thread
    if (!threadId) {
      // Send request
      const response = await executeCreateNewThread(
        "POST",
        `${protectedResources.api.endpoint}/threads`,
        {
          threadTitle: "New Thread",
          tool: tool?.apiName,
        }
      );
      if (!response) return;
      const createNewThreadData = await response.json();
      threadId = createNewThreadData.threadId;
      setNewThreadId(threadId!);

      // Update state
      addThread({
        _id: threadId!,
        title: "New Thread",
        messages: [],
        userUuid: "placeholder",
        lastUpdatedAt: new Date(),
        usedImageInThread: false,
      });

      addThreadToProfile(threadId!, "New Thread"); // update sidebar

      isCreatingNewThread = true;
    }

    // Add user's message to state
    addMessageToThread(threadId!, {
      _id: v4(),
      role: "user",
      createdAt: new Date(),
      text: userInput,
      images: imageBase64 ? [imageBase64] : [],
    });

    // Reset input state
    setUserInput("");
    setImageBase64(null);

    // Get response from server
    let chatApiUrl = `${protectedResources.api.endpoint}/threads/${threadId}/chat`;

    if (tool) {
      // Add tool if it has been requested
      chatApiUrl += `/${tool.apiName}`;
    }

    const response = await executeChat("POST", chatApiUrl, {
      text: userInput,
      model: model?.model,
      provider: model?.provider,
      images: imageBase64 ? [imageBase64] : [],
    });

    if (!response) {
      return;
    }

    // Add initial assistants's message to state
    addMessageToThread(threadId!, {
      _id: v4(),
      role: "assistant",
      createdAt: new Date(),
      text: "",
      images: [],
      toolFunctions: [],
      model: model?.model,
      provider: model?.provider,
    });

    // Get stream from response
    const readableStream = response.body!;
    const reader = readableStream.getReader();

    // Progressively stream and save chunks to state
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const textValue = new TextDecoder().decode(value);

      // Server might send multiple chunks at a time, so split to record each chunk
      for (const chunk of JsonParseService.splitJsonString(textValue)) {
        const llmChunk = JSON.parse(chunk) as LLMChunk;

        // Add chunk to last message in thread
        addStreamedLLMChunkToThread(threadId!, llmChunk);
      }
    }

    setLoading(false);

    if (isCreatingNewThread) {
      // If a new thread was created, navigate to the new thread page
      navigate("/thread/" + threadId);

      // Generate a new title for the thread
      const response = await executeGenerateThreadTitle(
        "PATCH",
        `${protectedResources.api.endpoint}/threads/${threadId}/generate-title`
      );

      // Get response from server which is the new thread
      const data = await response?.json();

      // Progressively update thread title on client side for a cool effect
      let runningTitle = "";
      let characterDelayMs = 50;
      for (const character of data.title) {
        runningTitle += character;
        updateThreadTitle(threadId!, runningTitle);
        updateThreadTitleInProfile(threadId!, runningTitle);
        await new Promise((resolve) => setTimeout(resolve, characterDelayMs));
      }

      // Reset new thread
      setNewThreadId("");
    }
  };

  return (
    <>
      <div className="flex flex-col w-full items-start gap-5 p-4 relative border-2 border-gray-500 rounded-2xl ">
        {imageBase64 && (
          <ImagePreview
            base64String={imageBase64}
            onCancel={() => setImageBase64(null)}
          />
        )}
        <div className="flex flex-row w-full gap-5 items-center">
          {model?.supportsImage && (
            <ImageUploadButton setImage={setImageBase64} />
          )}
          <textarea
            rows={1}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            name="content"
            ref={textAreaRef}
            placeholder="Type your message..."
            className="bg-transparent text-onChat w-full h-fit resize-none overflow-hidden outline-none"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          <ChatSubmitButton
            loading={loading}
            onClick={handleSubmit}
            textAreaValue={userInput}
          />
        </div>
      </div>
    </>
  );
}
