import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ClipLoader } from "react-spinners";

export default function ChatSubmitButton({
  textAreaValue,
  onClick,
  loading,
}: {
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  textAreaValue: string;
}) {
  let buttonColorStyle;

  if (loading) {
    buttonColorStyle = "bg-lightgrey200";
  } else {
    if (textAreaValue) {
      buttonColorStyle = "bg-gray-800 hover:bg-gray-900";
    } else {
      buttonColorStyle = "bg-lightgrey200";
    }
  }

  const sendIconColorStyle = textAreaValue ? "white" : "grey";

  return (
    <button
      onClick={onClick}
      disabled={!textAreaValue}
      className={`${buttonColorStyle} h-fit self-end p-2 rounded-lg`}
    >
      {loading ? (
        <ClipLoader
          className="text-center align-middle"
          color="gray"
          loading={true}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <FontAwesomeIcon
          color={sendIconColorStyle}
          icon={faArrowUp}
          size="sm"
          width={20}
          height={15}
        />
      )}
    </button>
  );
}
