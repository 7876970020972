import AuthButton from "../../../auth/AuthButton";
import useFetchedProfile from "../../../../hooks/data/useFetchedProfile";

export default function ProfileSettingsTab() {
  // const { instance } = useMsal();

  // const displayName = instance.getActiveAccount()?.name;

  const { profile } = useFetchedProfile();

  const displayName = profile?.email;

  return (
    <div className="">
      <h1 className="grow shrink block text-2xl">
        <span className="font-bold">User:</span> {displayName}
      </h1>
      <AuthButton className="mt-10 w-1/4 bg-slate-800" />
    </div>
  );
}
