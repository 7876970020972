import Slider from "rc-slider";
import { ReactNode, useEffect, useState } from "react";
import { models } from "../../../../constants/supportedModels";
import { Profile } from "../../../../schema.interfaces";
import React from "react";
import { protectedResources } from "../../../../auth.config";
import useFetchWithMsal from "../../../../hooks/useFetchWithMsal";
import { useModalStore } from "../../../../state/modal";
import { useProfileStore } from "../../../../state/profile";
import Button from "../../../ui/Button";

const sliderStyles = {
  styles: {
    rail: { backgroundColor: "gray" },
    handle: {
      backgroundColor: "white",
      borderColor: "rgb(30, 41, 59)",
    },
    track: { backgroundColor: "rgb(30, 41, 59)" },
    tracks: { backgroundColor: "red", borderColor: "green" },
  },
  dotStyle: {
    backgroundColor: "white",
    borderColor: "grey",
  },
  activeDotStyle: {
    backgroundColor: "white",
    borderColor: "rgb(30, 41, 59)",
  },
};

export default function ModelSettingsTab() {
  const profile = useProfileStore((state) => state.profile);
  const updateProfileSettings = useProfileStore(
    (state) => state.updateProfileSettings
  );
  const [settings, setSettings] = useState<Profile["settings"] | null>(null);
  const [settingsDidChange, setSettingsDidChange] = useState(false);

  // Refresh settings from profile
  useEffect(() => {
    if (profile?.settings) {
      setSettings(profile.settings);
    }
  }, [profile]);

  // Check if settings did change, update boolean value
  useEffect(() => {
    if (!profile?.settings) return;
    for (const key in settings) {
      if (
        settings[key as keyof typeof settings] !==
        profile.settings[key as keyof typeof profile.settings]
      ) {
        setSettingsDidChange(true);
        return;
      }
      setSettingsDidChange(false);
    }
  }, [settings, setSettingsDidChange, profile]);

  // For closing the modal
  const setModalChildren = useModalStore((state) => state.setChildren);
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);

  // Save settings request
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  const saveSettings = async () => {
    // Check if the settings even changed
    if (!settingsDidChange || !settings) return;

    // Send request
    await execute(
      "PUT",
      `${protectedResources.api.endpoint}/users/profile/settings`,
      {
        settings: { ...settings },
      }
    );

    // Update client side state
    updateProfileSettings(settings);

    handleCancel();
  };

  const handleCancel = () => {
    setModalChildren(null);
    setIsShowingModal(false);
  };

  if (!settings) return null;

  return (
    <div>
      <ModelSelector settings={settings} setSettings={setSettings} />
      <TemperatureSlider settings={settings} setSettings={setSettings} />
      <TopPSlider
        settings={settings}
        setSettings={setSettings}
        stepSize={0.1}
        min={0}
        max={1}
      />
      <div className="flex flex-row justify-end gap-4 mt-24 ml-auto w-1/2">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!settingsDidChange} onClick={saveSettings}>
          Save
        </Button>
      </div>
    </div>
  );
}

function ModelSelector({
  settings,
  setSettings,
}: {
  settings: Profile["settings"];
  setSettings: React.Dispatch<React.SetStateAction<Profile["settings"] | null>>;
}) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <p className="font-medium">Default Model</p>
        <p className="text-sm text-gray-500 mt-2">
          Controls the default model that is selected when you start a new
          thread.
        </p>
      </div>
      <select
        value={settings.defaultModel}
        onChange={(e) =>
          setSettings({ ...settings, defaultModel: e.target.value })
        }
        className="ml-2 border-slate-600 border-2 rounded-md p-3 hover:cursor-pointer"
      >
        {models.map((model) => (
          <option
            key={model.displayModel}
            value={model.model}
            className="hover:cursor-pointer"
          >
            {model.displayModel}
          </option>
        ))}
      </select>
    </div>
  );
}

function TemperatureSlider({
  settings,
  setSettings,
}: {
  settings: Profile["settings"];
  setSettings: React.Dispatch<React.SetStateAction<Profile["settings"] | null>>;
}) {
  const iToL = {
    0: "very low",
    1: "low",
    2: "medium",
    3: "high",
    4: "very high",
  };

  const lToI = {
    "very low": 0,
    low: 1,
    medium: 2,
    high: 3,
    "very high": 4,
  };

  return (
    <div className="mt-10">
      <p className="font-medium">Temperature</p>
      <p className="text-sm text-gray-500 mt-2">
        Controls the randomness or creativity of the generated text, where
        higher temperatures lead to more diverse outputs, and lower temperatures
        lead to more focused and deterministic outputs.
      </p>
      <div className="px-1 mt-3">
        <Slider
          {...sliderStyles}
          value={lToI[settings.temperature as keyof typeof lToI]}
          min={0}
          max={4}
          onChange={(t) =>
            setSettings({
              ...settings,
              temperature:
                typeof t === "number"
                  ? iToL[t as keyof typeof iToL]
                  : iToL[t[0] as keyof typeof iToL],
            })
          }
          step={1}
          marks={iToL}
        />
      </div>
    </div>
  );
}

function TopPSlider({
  settings,
  setSettings,
  stepSize,
  min,
  max,
}: {
  settings: Profile["settings"];
  setSettings: React.Dispatch<React.SetStateAction<Profile["settings"] | null>>;
  stepSize: number;
  min: number;
  max: number;
}) {
  const marks: Record<number, ReactNode> = {};

  for (let i = min; i <= max + stepSize; i += stepSize) {
    marks[i] = <p>{i.toFixed(1)}</p>;
  }

  return (
    <div className="mt-14">
      <p className="font-medium">TopP</p>
      <p className="text-sm text-gray-500 mt-2">
        Determines the probability distribution used to select the next word,
        focusing on the most likely words that cumulatively make up the top P
        percentage of probability mass.
      </p>

      <div className="px-1 mt-3">
        <Slider
          {...sliderStyles}
          value={settings.topP}
          min={min}
          max={max}
          onChange={(t) =>
            setSettings({
              ...settings,
              topP: typeof t === "number" ? t : t[0],
            })
          }
          step={stepSize}
          marks={marks}
        />
      </div>
    </div>
  );
}
