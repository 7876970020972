import useFetchedProfile from "../../hooks/data/useFetchedProfile";
import { useThreadsStore } from "../../state/threads";
import ChatArena from "../../components/chat/containers/ChatArena";
import ChatContainer from "../../components/chat/containers/ChatContainer";
import { useSearchParams } from "react-router-dom";
import { tools } from "../../constants/supportedTools";
import ChatHeader from "../../components/chat/containers/ChatHeader";
import PrimaryBar from "../../components/primary-bar/PrimaryBar";
import SecondaryBar from "../../components/secondary-bar/SecondaryBar";

export default function Home() {
  const { profile } = useFetchedProfile();
  const newThreadId = useThreadsStore((state) => state.newThread.id);
  const newThread = useThreadsStore((state) => state.threads[newThreadId]);

  // Is the user requesting a tool?
  const [searchParams] = useSearchParams();
  const toolName = searchParams.get("tool"); // Get the tool from the URL query params

  let tool;
  if (toolName) {
    tool = tools.find((tool) => tool.apiName === toolName);
  }

  return (
    <main className="flex flex-row">
      <PrimaryBar selectedThreadId={newThreadId} profile={profile} />
      <ChatArena>
        {/* Empty ChatHeader just for some buffering when a new thread is being
        displayed before navigating to thread page */}
        <ChatHeader></ChatHeader>
        <ChatContainer thread={newThread} tool={tool} />
      </ChatArena>
      <SecondaryBar />
    </main>
  );
}
