import { create } from "zustand";
import { Profile } from "../schema.interfaces";

interface ProfileState {
  profile: Profile | null;
  setProfile: (profile: Profile) => void;
  addThreadToProfile: (threadId: string, title: string) => void;
  deleteThreadFromProfile: (threadId: string) => void;
  updateThreadTitleInProfile: (threadId: string, newTitle: string) => void;
  updateProfileSettings: (settings: Profile["settings"]) => void;
}

export const useProfileStore = create<ProfileState>()((set) => ({
  profile: null,
  setProfile: (profile: Profile) => set((state) => ({ ...state, profile })),
  addThreadToProfile(threadId, title) {
    set((state) => {
      if (!state.profile) {
        return state;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          threads: [
            ...state.profile.threads,
            { _id: threadId, title, lastUpdatedAt: new Date() },
          ],
        },
      };
    });
  },

  deleteThreadFromProfile(threadId) {
    set((state) => {
      if (!state.profile) {
        return state;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          threads: state.profile.threads.filter(
            (thread) => thread._id !== threadId
          ),
        },
      };
    });
  },
  updateThreadTitleInProfile(threadId, newTitle) {
    set((state) => {
      if (!state.profile) {
        return state;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          threads: state.profile.threads.map((thread) => {
            if (thread._id === threadId) {
              return {
                ...thread,
                title: newTitle,
              };
            }

            return thread;
          }),
        },
      };
    });
  },
  updateProfileSettings(settings) {
    set((state) => {
      if (!state.profile) {
        return state;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          settings,
        },
      };
    });
  },
}));
