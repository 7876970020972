import React, { useEffect, useState } from "react";

export default function AuthenticatedContainer({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("uuid")) {
      setShowChildren(true);
    } else {
      setShowChildren(false);
    }
  }, [setShowChildren]);

  return <div>{showChildren ? children : null}</div>;
}
