import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { protectedResources } from "../../../../auth.config";
import useFetchWithMsal from "../../../../hooks/useFetchWithMsal";
import { useModalStore } from "../../../../state/modal";
import { useThreadsStore } from "../../../../state/threads";
import Button from "../../../ui/Button";
import { ThreadActionsDropdownItem } from "../ThreadActionsDropdown";
import { iconWidth } from "../../../../constants/ui";
import { useProfileStore } from "../../../../state/profile";
import { useEffect, useRef, useState } from "react";

export default function RenameItem({ threadId }: { threadId: string }) {
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const setChildren = useModalStore((state) => state.setChildren);

  const handleClick = () => {
    setIsShowingModal(true);
    setChildren(<RenameItemModal threadId={threadId} />);
  };

  return (
    <ThreadActionsDropdownItem onClick={handleClick}>
      <FontAwesomeIcon icon={faEdit} size="sm" width={iconWidth} />
      <p className="font-medium">Rename</p>
    </ThreadActionsDropdownItem>
  );
}

function RenameItemModal({ threadId }: { threadId: string }) {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  const updateThreadTitle = useThreadsStore((state) => state.updateThreadTitle);
  const updateThreadTitleInProfile = useProfileStore(
    (state) => state.updateThreadTitleInProfile
  );
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const setChildren = useModalStore((state) => state.setChildren);

  const profile = useProfileStore((state) => state.profile);
  const threads = profile?.threads;
  const threadTitle = threads
    ? threads.find((thread) => thread._id === threadId)?.title || ""
    : "";

  const [newTitle, setNewTitle] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNewTitle(threadTitle);
  }, [threadTitle]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleRename = async () => {
    // Send rename request
    await execute(
      "PATCH",
      `${protectedResources.api.endpoint}/threads/${threadId}`,
      {
        threadTitle: newTitle,
      }
    );

    // Edit thread title in state
    updateThreadTitle(threadId, newTitle);
    updateThreadTitleInProfile(threadId, newTitle);

    handleCancel();
  };

  const handleCancel = () => {
    setIsShowingModal(false);
    setChildren(null);
  };

  return (
    <div>
      <div className="p-6 border-b-2 border-gray-300">
        <h2 className="font-bold text-lg">Rename thread</h2>
      </div>
      <div className="p-6">
        <input
          ref={inputRef}
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          className="w-full outline-none border-2 border-slate-800 rounded-md p-3"
        />
        <div className="flex justify-end mt-6">
          <Button
            disabled={newTitle === threadTitle}
            className="w-fit"
            onClick={handleRename}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
