import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

export default function Modal({
  children,
  closeModal,
}: {
  children?: ReactNode;
  closeModal: VoidFunction;
}) {
  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-black opacity-70 z-50"
        onClick={closeModal}
      ></div>
      {/* Modal */}
      <div className="bg-white fixed w-11/12 md:w-5/6 lg:w-3/4 xl:2/3 2xl:w-1/3 top-5 left-1/2 -translate-x-1/2 shadow-lg z-50 rounded-md">
        <FontAwesomeIcon
          onClick={closeModal}
          className="hover:cursor-pointer absolute top-3 right-3"
          icon={faClose}
          size="lg"
        />
        {children}
      </div>
    </>
  );
}
