"use client";

import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask } from "@fortawesome/free-solid-svg-icons";

export default function LabButton() {
  const navigate = useNavigate();

  return (
    <Button
      className="border-slate-800 border-2 bg-white text-slate-800"
      onClick={() => {
        navigate("/lab");
      }}
    >
      <FontAwesomeIcon icon={faFlask} size="lg" />
    </Button>
  );
}
