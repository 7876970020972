// Stylesheets
import "./index.css";
import "rc-slider/assets/index.css";

import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <AuthProvider>
  <App />
  // </AuthProvider>
);
