import { ToolRecord } from "../../../constants/supportedTools";

export default function ToolInfoSmall({ tool }: { tool: ToolRecord }) {
  return (
    <div className="text-slate-800 grid w-full text-center md:text-left">
      <p className="uppercase tracking-wider text-sm font-medium">Tool</p>

      <p className="font-bold text-xl">{tool.displayName}</p>
    </div>
  );
}
