export class JsonParseService {
  /*
Split a string such as this: {"type":"text","message":""}{"type":"text","message":"The"}'

Into a list of strings such as this: ['{"type":"text","message":""}', '{"type":"text","message":"The"}']
        */
  static splitJsonString(jsonString: string): string[] {
    const jsonStrings: string[] = [];
    let currentJsonString = "";
    let openBrackets = 0;
    for (let i = 0; i < jsonString.length; i++) {
      const char = jsonString[i];
      currentJsonString += char;
      if (char === "{") {
        openBrackets++;
      } else if (char === "}") {
        openBrackets--;
        if (openBrackets === 0) {
          jsonStrings.push(currentJsonString);
          currentJsonString = "";
        }
      }
    }
    return jsonStrings;
  }
}
