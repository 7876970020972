import { create } from "zustand";
import { LLMChunk, Message, Thread } from "../schema.interfaces";

interface ThreadsState {
  threads: Record<string, Thread>;
  getThread: (threadId: string) => Thread;
  addThread: (thread: Thread) => void;
  deleteThread: (threadId: string) => void;
  updateThreadTitle: (threadId: string, newTitle: string) => void;
  updateThreadImageUsed: (threadId: string, usedImage: boolean) => void;
  addMessageToThread: (threadId: string, message: Message) => void;
  addStreamedLLMChunkToThread: (threadId: string, chunk: LLMChunk) => void; // Adds streamed chunk to the last message in the thread
  newThread: {
    id: string;
    setNewThreadId: (id: string) => void;
  };
}

export const useThreadsStore = create<ThreadsState>()((set, get) => ({
  threads: {},
  getThread: (threadId: string) => get().threads[threadId],
  addThread: (thread: Thread) =>
    set((state) => ({
      ...state,
      threads: {
        ...state.threads,
        [thread._id]: thread,
      },
    })),
  deleteThread: (threadId: string) =>
    set((state) => {
      if (!state.threads[threadId]) {
        return state;
      }

      const newThreads = { ...state.threads };
      delete newThreads[threadId];
      return {
        ...state,
        threads: newThreads,
      };
    }),
  updateThreadTitle(threadId, newTitle) {
    set((state) => {
      if (!state.threads[threadId]) {
        return state;
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            title: newTitle,
          },
        },
      };
    });
  },
  updateThreadImageUsed: (threadId: string, usedImage: boolean) =>
    set((state) => {
      if (!state.threads[threadId]) {
        return state;
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            usedImageInThread: usedImage,
          },
        },
      };
    }),
  addMessageToThread: (threadId: string, message: Message) =>
    set((state) => {
      if (!state.threads[threadId]) {
        return state;
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            messages: [...state.threads[threadId].messages, message],
          },
        },
      };
    }),
  addStreamedLLMChunkToThread: (threadId: string, chunk: LLMChunk) =>
    set((state) => {
      if (!state.threads[threadId]) {
        return state;
      }

      const thread = state.threads[threadId];
      const lastMessage = thread.messages[thread.messages.length - 1];

      if (chunk.type === "text") {
        lastMessage.text += chunk.message;
      } else {
        lastMessage.toolFunctions?.push(chunk.message);
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: {
            ...thread,
            messages: [
              ...thread.messages.slice(0, -1),
              {
                ...lastMessage,
                toolFunctions: [...(lastMessage.toolFunctions || [])],
              },
            ],
          },
        },
      };
    }),
  newThread: {
    id: "",
    setNewThreadId: (id: string) =>
      set((state) => ({
        ...state,
        newThread: {
          ...state.newThread,
          id,
        },
      })),
  },
}));
