"use client";

import { useEffect, useRef, useState } from "react";
import { protectedResources } from "../../../auth.config";
import useAutosizeTextArea from "../../../hooks/useAutoSizeTextArea";
import { useModelStore } from "../../../state/model";
import ChatSubmitButton from "../../chat/input/ChatSubmitButton";
import { ModelRecord } from "../../../constants/supportedModels";
import { fetchWithMsal } from "../../../fetch/fetchWithMsal";
import { LLMChunk } from "../../../schema.interfaces";
import { JsonParseService } from "../../../services/jsonParse.service";
import { useChatInputSetterState } from "../../../state/chatInput";

export default function LabChatInput({
  models,
  setOutputs,
  setLoadings,
  setDones,
  userInput,
  setUserInput,
}: {
  models: ModelRecord[];
  setOutputs: React.Dispatch<React.SetStateAction<string[]>>;
  setLoadings: React.Dispatch<React.SetStateAction<boolean[]>>;
  setDones: React.Dispatch<React.SetStateAction<boolean[]>>;
  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const model = useModelStore((state) => state.model);
  const setChatInputSetter = useChatInputSetterState(
    (state) => state.setChatInputSetter
  );

  // Auto resize text area
  useAutosizeTextArea(textAreaRef.current, userInput);

  // Focus on text area when component mounts
  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  // Clear inputs if the user changes the model
  useEffect(() => {
    setUserInput("");
  }, [model, setUserInput]);

  // Set chat input setter
  useEffect(() => {
    setChatInputSetter(setUserInput);
  }, [setChatInputSetter, setUserInput]);

  // Execute when a user sends a message
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userInput.trim() === "") return;

    setLoading(true);

    // Get streams for each model
    const responses = await Promise.all(
      models.map((model) =>
        fetchWithMsal({
          method: "POST",
          endpoint: `${protectedResources.api.endpoint}/threads/chat/one-off`,
          data: {
            text: userInput,
            model: model.model,
            provider: model.provider,
          },
        })
      )
    );

    // Get streams from responses
    const streams = responses.map((response) => response!.body!);

    // Get readers from streams
    const readers = streams.map((stream) => stream.getReader());

    // Stream outputs from readers to state
    await Promise.all(
      readers.map((reader, index) =>
        streamOutputFromReader(reader, index, setOutputs, setLoadings)
      )
    );

    setLoading(false);
  };

  const streamOutputFromReader = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    index: number,
    setOutputs: React.Dispatch<React.SetStateAction<string[]>>,
    setLoadings: React.Dispatch<React.SetStateAction<boolean[]>>
  ) => {
    // Set auxiliary states
    setLoadings((loadings) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setDones((dones) => {
      const newDones = [...dones];
      newDones[index] = false;
      return newDones;
    });

    // Clear outputs
    setOutputs((currentOutputs) => {
      const newOutputs = [...currentOutputs];
      newOutputs[index] = "";
      return newOutputs;
    });

    // Stream outputs to state
    const decoder = new TextDecoder("utf-8");

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const textValue = decoder.decode(value);

      // Server might send multiple chunks at a time, so split to record each chunk
      for (const chunk of JsonParseService.splitJsonString(textValue)) {
        const llmChunk = JSON.parse(chunk) as LLMChunk;

        setOutputs((currentOutputs) => {
          const newOutputs = [...currentOutputs];
          newOutputs[index] = (newOutputs[index] || "") + llmChunk.message;
          return newOutputs;
        });
      }
    }

    // Reset auxiliary states
    setLoadings((loadings) => {
      const newLoadings = [...loadings];
      newLoadings[index] = false;
      return newLoadings;
    });

    setDones((dones) => {
      const newDones = [...dones];
      newDones[index] = true;
      return newDones;
    });
  };

  return (
    <div className="flex flex-col w-full items-start gap-5 p-4 relative border-2 border-gray-500 rounded-2xl">
      <div className="flex flex-row w-full gap-5 items-center">
        <textarea
          rows={1}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          name="content"
          ref={textAreaRef}
          placeholder="Type your message..."
          className="bg-transparent text-onChat w-full h-fit resize-none overflow-hidden outline-none"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
        />
        <ChatSubmitButton
          loading={loading}
          onClick={handleSubmit}
          textAreaValue={userInput}
        />
      </div>
    </div>
  );
}
