import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconWidth } from "../../../constants/ui";
import ClipLoader from "react-spinners/ClipLoader";

export default function AssistantMessage({
  text,
  model,
  showTextOnly = false,
  toolFunctions,
}: {
  text: string;
  model: string;
  showTextOnly?: boolean;
  toolFunctions?: string[];
}) {
  return (
    <div className="flex gap-4">
      {!showTextOnly && (
        <FontAwesomeIcon
          color="black"
          width={iconWidth}
          icon={faRobot}
          size="lg"
        />
      )}

      <div className="flex flex-col gap-3">
        {!showTextOnly && (
          <p className="text-sm font-bold text-onChat">
            Assistant <span className="font-normal">({model})</span>
          </p>
        )}
        {!text && (
          <div className="flex flex-col gap-2">
            {/* Only show tools if there is no text */}
            {toolFunctions?.map((toolFunction) => (
              <div className="flex items-center gap-2">
                <ClipLoader
                  className="text-center align-middle"
                  color="#4f46e5"
                  loading={true}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <p className="font-medium text-indigo-600">{toolFunction}</p>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col gap-2">{splitTextIntoParas(text)}</div>
      </div>
    </div>
  );
}

// Checks the text for double asterisks ** and bolds the text between them
const boldText = (text: string, style?: string) => {
  const boldPattern = /\*\*(.*?)\*\*/g;
  let match;
  let lastIndex = 0;
  const boldedText = [];
  while ((match = boldPattern.exec(text)) !== null) {
    boldedText.push(text.slice(lastIndex, match.index));
    boldedText.push(
      <span key={match.index} className={"font-bold " + style}>
        {match[1]}
      </span>
    );
    lastIndex = boldPattern.lastIndex;
  }
  boldedText.push(text.slice(lastIndex));
  return boldedText;
};

const splitTextIntoParas = (text: string) => {
  return text.split("\n").map((line, index) => {
    // Check for dot points
    if (line.startsWith("- ") || line.startsWith("* ")) {
      return (
        <li key={index} className="text-onChat">
          {boldText(line.slice(2))}
        </li>
      );
    }
    // Check for numbered lists (starting with 1. or 2. etc.)
    const searchPattern = new RegExp("^[0-9]+.");
    if (searchPattern.test(line)) {
      return (
        <p key={index} className="ml-3 text-onChat">
          <span className="font-bold">{searchPattern.exec(line)}</span>{" "}
          {boldText(line.split(" ").slice(1).join(" "))}
        </p>
      );
    }
    return (
      <p key={index} className={`text-onChat ${line === "" && "mt-4"}`}>
        {boldText(line)}
      </p>
    );
  });
};
