"use client";

import { useRef, useEffect } from "react";
import { Message } from "../../../schema.interfaces";
import AssistantMessage from "./AssistantMessage";
import UserMessage from "./UserMessage";
import { models } from "../../../constants/supportedModels";

export default function ChatOutput({ messages }: { messages: Message[] }) {
  const containerRef = useRef<HTMLDivElement>(null);

  // set new scroll height every time a new message comes in
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={containerRef}
      className="grow shrink flex flex-col gap-16 overflow-y-scroll"
    >
      {messages.map((message) => {
        return message.role === "user" ? (
          <UserMessage
            key={message._id}
            image={message.images[0]}
            text={message.text}
          />
        ) : (
          <AssistantMessage
            key={message._id}
            text={message.text}
            toolFunctions={message.toolFunctions}
            model={
              models.find((model) => model.model === message.model)
                ?.displayModel || ""
            }
          />
        );
      })}
    </div>
  );
}
