import { useEffect, useState } from "react";
import { models } from "../../../constants/supportedModels";
import LabChatInput from "../input/LabChatInput";
import LabCard from "./LabCard";
import { useChatInputSetterState } from "../../../state/chatInput";

export default function LabContainer() {
  // List of models being used currently in the lab
  const [currentModels, setCurrentModels] = useState([models[2], models[3]]);

  // List of message streams for each lab card
  const [outputs, setOutputs] = useState<string[]>([]);

  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [dones, setDones] = useState<boolean[]>([]);

  // Current user input
  const [userInput, setUserInput] = useState("");

  // Set chat input setter
  const setChatInputSetter = useChatInputSetterState(
    (state) => state.setChatInputSetter
  );
  useEffect(() => setChatInputSetter(setUserInput), [setChatInputSetter]);

  return (
    <div className="grow shrink h-full w-full flex flex-col gap-12 p-10 relative">
      {/* Display Chat Input */}
      <div className="mx-auto w-5/6">
        <LabChatInput
          models={currentModels}
          setOutputs={setOutputs}
          setLoadings={setLoadings}
          setDones={setDones}
          userInput={userInput}
          setUserInput={setUserInput}
        />
      </div>
      <div className="grow shrink relative overflow-y-scroll h-full flex flex-row gap-16 w-5/6 mx-auto">
        {/* Display Lab Cards for each model */}
        {currentModels.map((modelRecord, index) => (
          <LabCard
            key={modelRecord.model}
            model={modelRecord}
            validModelChoices={models.filter(
              (model) => !currentModels.includes(model)
            )}
            onModelChange={(modelRecord) => {
              const newModels = [...currentModels];
              newModels[index] = modelRecord;
              setCurrentModels(newModels);
            }}
            userInput={userInput}
            output={outputs[index]}
            loading={loadings[index]}
            done={dones[index]}
          />
        ))}
      </div>
    </div>
  );
}
