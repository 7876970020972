import ChatInput from "../input/ChatInput";
import ChatOutput from "../output/ChatOutput";
import ModelGridSelection from "../../model/ModelSelectionGrid";
import { useEffect, useState } from "react";
import { useModelStore } from "../../../state/model";
import { useThreadsStore } from "../../../state/threads";
import { Thread } from "../../../schema.interfaces";
import { useProfileStore } from "../../../state/profile";
import { ToolRecord } from "../../../constants/supportedTools";
import ToolInfoLarge from "../../tools/info/ToolInfoLarge";
import ClipLoader from "react-spinners/ClipLoader";

export default function ChatContainer({
  thread,
  tool,
}: {
  thread: Thread | null;
  tool?: ToolRecord;
}) {
  const setModel = useModelStore((state) => state.setModel);
  const threads = useThreadsStore((state) => state.threads);
  const newThreadId = useThreadsStore((state) => state.newThread.id);
  let newThread: Thread | null = null;

  const profile = useProfileStore((state) => state.profile);
  const [defaultModelHasBeenSet, setDefaultModelHasBeenSet] = useState(false);

  // Set default model from profile settings
  useEffect(() => {
    if (profile?.settings.defaultModel) {
      setModel(profile.settings.defaultModel);
      setDefaultModelHasBeenSet(true);
    }
  }, [profile, setModel]);

  // Set the document title
  useEffect(() => {
    document.title = thread?.title || "TUT GPT";
  }, [thread]);

  // Set the current model to the last model used in this thread
  useEffect(() => {
    const lastMessage = thread?.messages[thread.messages.length - 1];
    if (thread && lastMessage && lastMessage.model) {
      setModel(lastMessage.model);
    }
  }, [thread, setModel]);

  const isOnHomePage = window.location.pathname === "/";

  // If this is the home page, but a new thread has been created, then we will display the messages of the new thread
  if (newThreadId) {
    newThread = threads[newThreadId];
  }

  // Only 1 of the 3 following booleans will be true at a time
  const showNewPage = !newThread && isOnHomePage;
  const showThread = thread && !newThread;
  const showNewThread = !!newThread && !!newThreadId;

  useEffect(
    () => console.log({ showNewPage, showThread, showNewThread }),
    [showNewPage, showThread, showNewThread]
  );

  useEffect(() => console.log({ profile }), [profile]);

  return (
    <div className="grow shrink overflow-y-scroll mx-auto w-full md:w-11/12 lg:w-5/6 max-w-[50rem] flex flex-col gap-10 pb-5 px-5">
      {((!showNewPage && !showThread && !showNewThread) ||
        !defaultModelHasBeenSet) && (
        <div className="relative grow shrink h-full w-full">
          <ClipLoader
            className="text-slate-800 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            loading={true}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      {showNewPage && (
        <>
          {tool ? <ToolInfoLarge tool={tool} /> : <ModelGridSelection />}
          <ChatInput tool={tool} threadId={null} />
        </>
      )}

      {showThread && (
        <>
          <ChatOutput messages={thread.messages} />
          <ChatInput tool={tool} threadId={thread._id} />
        </>
      )}

      {showNewThread && (
        <>
          <ChatOutput messages={newThread!.messages} />
          <ChatInput tool={tool} threadId={newThreadId} />
        </>
      )}
    </div>
  );
}
