"use client";

import { savedPrompts } from "../../constants/savedPrompts";
import { useSidebarStore } from "../../state/sidebar";
import CollapsibleSidebar from "../ui/collapsible/CollapsibleSidebar";
import SavedPrompt from "./containers/SavedPrompt";

export default function SecondaryBar() {
  const isShowingSecondaryBar = useSidebarStore(
    (state) => state.isShowingSecondaryBar
  );
  const hideSecondaryBar = useSidebarStore((state) => state.hideSecondaryBar);
  const showSecondaryBar = useSidebarStore((state) => state.showSecondaryBar);

  return (
    <CollapsibleSidebar
      orientation="right"
      isShowingSidebar={isShowingSecondaryBar}
      showSidebar={showSecondaryBar}
      hideSidebar={hideSecondaryBar}
    >
      <p className="font-bold text-center mb-5 text-lg">Saved Prompts</p>
      <div className="flex flex-col gap-3 pr-3 overflow-scroll">
        {savedPrompts.map((prompt) => (
          <SavedPrompt
            key={prompt.prompt}
            title={prompt.title}
            prompt={prompt.prompt}
          />
        ))}
      </div>
    </CollapsibleSidebar>
  );
}
