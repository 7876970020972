import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <main className="mx-10 md:mx-auto md:w-1/2 lg:w-1/2 xl:w-1/4 mt-32 bg-black text-center rounded-lg p-10">
      <p className="font-bold text-4xl text-white">404 Not Found</p>
      <Button
        onClick={() => navigate("/")}
        className="bg-white text-slate-800 w-full mt-8"
      >
        Back to Home
      </Button>
      <img
        alt="Time Under Tension Logo"
        className="mx-auto w-1/2 mt-24"
        src="/logo-large.png"
      />
    </main>
  );
}
