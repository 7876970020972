import { create } from "zustand";

interface ChatInputSetterState {
  setChatInput: (chatInput: string) => void;
  setChatInputSetter: (chatInputSetter: (chatInput: string) => void) => void;
}

export const useChatInputSetterState = create<ChatInputSetterState>()(
  (set) => ({
    setChatInput: () => {},
    setChatInputSetter: (chatInputSetter) =>
      set({ setChatInput: chatInputSetter }),
  })
);
