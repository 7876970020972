import { useState, useCallback } from "react";

import { PopupRequest } from "@azure/msal-browser";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest: PopupRequest, saveJson = true) => {
  // const { instance } = useMsal();
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState<AuthError | null>(null);
  const [data, setData] = useState<any>(null);

  // const { result } = useMsalAuthentication(InteractionType.Popup, {
  //   ...msalRequest,
  //   account: instance.getActiveAccount()!,
  //   redirectUri: "/",
  // });

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (method: string, endpoint: string, data?: any) => {
    // Local storage auth
    try {
      // Get authorization token
      const uuid = localStorage.getItem("uuid");
      if (uuid) {
        let response = null;
        const headers = new Headers();
        // Add authorization token to headers
        headers.append("Authorization", `Bearer ${uuid}`);
        if (data) headers.append("Content-Type", "application/json");

        let options = {
          method: method,
          headers: headers,
          body: data ? JSON.stringify(data) : undefined,
        };
        response = await fetch(endpoint, options);
        if (saveJson) {
          const json = await response.json();
          setData(json);
        }
        return response; // Return the response object
      }
    } catch (error) {
      console.error(error);
    }

    // MSAL auth (not being used)
    // if (result) {
    //   try {
    //     // save access token to session storage for use in server functions
    //     window.sessionStorage.setItem("accessToken", result.accessToken);
    //     let response = null;
    //     const headers = new Headers();
    //     const bearer = `Bearer ${result.accessToken}`;
    //     headers.append("Authorization", bearer);
    //     if (data) headers.append("Content-Type", "application/json");
    //     let options = {
    //       method: method,
    //       headers: headers,
    //       body: data ? JSON.stringify(data) : undefined,
    //     };
    //     setIsLoading(true);
    //     response = await fetch(endpoint, options);
    //     if (saveJson) {
    //       try {
    //         const json = await response.json();
    //         setData(json);
    //       } catch (error) {}
    //     }
    //     setIsLoading(false);
    //     return response; // Return the response object
    //   } catch (e: any) {
    //     setError(e);
    //     setIsLoading(false);
    //     throw e;
    //   }
    // }
  };

  return {
    data,
    execute: useCallback(execute, [saveJson]), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMsal;
export type ExecutorFunction = ReturnType<typeof useFetchWithMsal>["execute"];
