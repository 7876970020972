// Use this function if you cannot use the useFetchWithMsal hook directly in your component. This function will allow you to fetch data from an API using the MSAL authentication token. This function is used in the ChatInput component to send messages to the server. The function takes an access token, data, endpoint, and method as parameters. The access token is the token received from the MSAL authentication. The data is the data to be sent to the endpoint. The endpoint is the URL of the API endpoint. The method is the HTTP method to be used for the request. The function returns the JSON response from the API.
export async function fetchWithMsal({
  data,
  endpoint,
  method,
}: {
  method: string;
  endpoint: string;
  data: any;
}) {
  try {
    const accessToken = localStorage.getItem("uuid") || "";

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (data) headers.append("Content-Type", "application/json");

    let options = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };

    return fetch(endpoint, options);
  } catch (e: any) {
    console.error(e);
    return null;
  }
}
