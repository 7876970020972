import { useEffect } from "react";
import useFetchWithMsal from "../useFetchWithMsal";
import { protectedResources } from "../../auth.config";
import { useThreadsStore } from "../../state/threads";
import { Thread } from "../../schema.interfaces";

export default function useFetchedThread(threadId: string): {
  thread: Thread | null;
} {
  const { data: threadDataJson, execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  const addThread = useThreadsStore((state) => state.addThread);
  const thread = useThreadsStore((state) => state.getThread(threadId));

  useEffect(() => {
    if (threadId.trim() !== "") {
      execute("GET", `${protectedResources.api.endpoint}/threads/${threadId}`);
    }
  }, [execute, threadId]);

  useEffect(() => {
    if (threadDataJson) {
      addThread(threadDataJson);
    }
  }, [threadDataJson, addThread]);

  return {
    thread,
  };
}
