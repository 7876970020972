import { create } from "zustand";

interface SidebarState {
  isShowingPrimaryBar: boolean;
  showPrimaryBar: () => void;
  hidePrimaryBar: () => void;

  isShowingSecondaryBar: boolean;
  showSecondaryBar: () => void;
  hideSecondaryBar: () => void;
}

export const useSidebarStore = create<SidebarState>()((set) => ({
  isShowingPrimaryBar: true,
  showPrimaryBar: () => set({ isShowingPrimaryBar: true }),
  hidePrimaryBar: () => set({ isShowingPrimaryBar: false }),
  isShowingSecondaryBar: false,
  showSecondaryBar: () => set({ isShowingSecondaryBar: true }),
  hideSecondaryBar: () => set({ isShowingSecondaryBar: false }),
}));
