import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconWidth } from "../../../constants/ui";

export default function UserMessage({
  text,
  image,
}: {
  text: string;
  image?: string;
}) {
  return (
    <div className="flex gap-4">
      <FontAwesomeIcon
        color="black"
        width={iconWidth}
        icon={faUser}
        size="lg"
      />
      <div className="flex flex-col gap-3">
        <p className="text-sm font-bold text-onChat">User</p>
        {image && (
          <img alt="Uploaded" className="w-[200px] rounded-md" src={image} />
        )}
        <div className="">{text}</div>
      </div>
    </div>
  );
}
