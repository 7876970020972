import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { protectedResources } from "../../../../auth.config";
import useFetchWithMsal from "../../../../hooks/useFetchWithMsal";
import { useModalStore } from "../../../../state/modal";
import { useThreadsStore } from "../../../../state/threads";
import Button from "../../../ui/Button";
import { ThreadActionsDropdownItem } from "../ThreadActionsDropdown";
import { iconWidth } from "../../../../constants/ui";
import { useProfileStore } from "../../../../state/profile";

export default function DeleteItem({ threadId }: { threadId: string }) {
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const setChildren = useModalStore((state) => state.setChildren);

  const handleClick = () => {
    setIsShowingModal(true);
    setChildren(<DeleteItemModal threadId={threadId} />);
  };

  return (
    <ThreadActionsDropdownItem onClick={handleClick}>
      <FontAwesomeIcon icon={faTrash} size="sm" width={iconWidth} />
      <p className="font-medium">Delete</p>
    </ThreadActionsDropdownItem>
  );
}

function DeleteItemModal({ threadId }: { threadId: string }) {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  const navigate = useNavigate();
  const deleteThread = useThreadsStore((state) => state.deleteThread);
  const deleteThreadFromProfile = useProfileStore(
    (state) => state.deleteThreadFromProfile
  );
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const setChildren = useModalStore((state) => state.setChildren);
  const profile = useProfileStore((state) => state.profile);

  const handleDelete = async () => {
    // Send delete request
    await execute(
      "DELETE",
      `${protectedResources.api.endpoint}/threads/${threadId}`
    );

    // Delete thread from state
    deleteThread(threadId);
    deleteThreadFromProfile(threadId);

    navigate("/");

    handleCancel();
  };

  const handleCancel = () => {
    setIsShowingModal(false);
    setChildren(null);
  };

  const threads = profile?.threads;
  if (!threads) return null;

  const threadTitle = threads.find((thread) => thread._id === threadId)?.title;

  return (
    <>
      <div className="p-6 border-b-2 border-gray-300">
        <h2 className="font-bold text-lg">Delete thread</h2>
      </div>
      <div className="p-6">
        <p>Are you sure you want to delete this thread?</p>
        <p className="mt-4 mb-10">
          Thread: <span className="font-bold">{threadTitle}</span>
        </p>
        <div className="flex flex-row justify-end gap-4">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button className="bg-red-600" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </>
  );
}
