import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useChatInputSetterState } from "../../../state/chatInput";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function SavedPrompt({
  title,
  prompt,
}: {
  title: string;
  prompt: string;
}) {
  const setChatInput = useChatInputSetterState((state) => state.setChatInput);

  const onClick = () => setChatInput(prompt);

  return (
    <div
      onClick={onClick}
      className="hover-parent-visib p-3 relative hover:rounded-lg hover:cursor-pointer hover:bg-lightgrey200 border-2 border-b-gray-400 "
    >
      <div className="hover-child-visib">
        <div className="absolute top-2 right-2 bg-slate-800 rounded-lg w-[30px] h-[30px]">
          <FontAwesomeIcon
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            icon={faArrowLeft}
            color="white"
          />
        </div>
      </div>
      <p className="font-bold mb-2">{title}</p>
      <p>{prompt}</p>
    </div>
  );
}
