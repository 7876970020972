export interface ModelRecord {
  displayModel: string;
  displayProvider: string;
  model: string;
  provider: string;
  supportsImage?: boolean;
}

export const models: ModelRecord[] = [
  {
    model: "gpt-3.5-turbo",
    provider: "openai",
    displayModel: "GPT 3.5 Turbo",
    displayProvider: "OpenAI",
  },
  {
    model: "gpt-4",
    provider: "openai",
    displayModel: "GPT 4",
    displayProvider: "OpenAI",
  },
  {
    model: "gpt-4-turbo",
    provider: "openai",
    displayModel: "GPT 4 Turbo",
    displayProvider: "OpenAI",
    supportsImage: true,
  },
  {
    model: "gemini-1.5-pro-latest",
    provider: "google",
    displayModel: "Gemini 1.5 Pro",
    displayProvider: "Google",
  },
];

export const imageSupportedModels = ["gpt-4-turbo", "gpt-4-vision-preview"];
