import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function CollapseSidebarButton({
  isShowingSidebar,
  showSidebar,
  hideSidebar,
  orientation,
}: {
  isShowingSidebar: boolean;
  showSidebar: () => void;
  hideSidebar: () => void;
  orientation: "left" | "right";
}) {
  const [isHovering, setIsHovering] = useState(false);

  const bendLeftOnOpen = isHovering
    ? `bar-active ${isShowingSidebar ? "rotate-[20deg]" : "-rotate-[20deg]"}`
    : "";

  const bendLeftOnClose = isHovering
    ? `bar-active ${isShowingSidebar ? "-rotate-[20deg]" : "rotate-[20deg]"}`
    : "";

  const onClick = () => {
    if (isShowingSidebar) {
      hideSidebar();
    } else {
      showSidebar();
    }
  };

  const chevronShift = orientation === "left" ? "-right-10" : "-left-10";
  const hamburgerShift = orientation === "left" ? "-right-10" : "-left-10";

  return (
    <>
      {/* Show chevron when the screen is large */}
      <div
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={
          "hidden md:block absolute top-1/2 -translate-y-1/2 hover:cursor-pointer pl-5 p-3 " +
          chevronShift
        }
      >
        <div
          className={`bar topbar ${
            orientation === "left" ? bendLeftOnOpen : bendLeftOnClose
          }`}
        ></div>
        <div
          className={`bar bottombar ${
            orientation === "left" ? bendLeftOnClose : bendLeftOnOpen
          }`}
        ></div>
      </div>
      {/* Show hamburger when the screen is small */}
      <div
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={
          "md:hidden absolute top-2 hover:cursor-pointer p-3 " + hamburgerShift
        }
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </div>
    </>
  );
}
