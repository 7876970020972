export interface ToolRecord {
  apiName: string;
  displayName: string;
  description: string;
}

export const tools: ToolRecord[] = [
  {
    apiName: "weather",
    displayName: "Weather",
    description: "Get real-time weather for a location.",
  },
];
