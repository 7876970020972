export const savedPrompts = [
  {
    title: "Write a creative brief",
    prompt:
      "You are the award-winning creative director of a creative ad agency. Using this information, create a creative brief that will lead to innovative ideas. Ask me for any missing information. DO NOT come up with ideas, this is just a brief. The brief is... [copy and paste brief]",
  },
  {
    title: "Headline formulas",
    prompt:
      "You're an expert copywriter. I'm going to give you a topic, you give me some proven formulas for successful headlines. [Insert your topic or brief]",
  },
  {
    title: "Headline generator",
    prompt:
      "You're an expert copywriter. Using the [insert headline formula] formula, please come up with 2 headlines based on this topic [insert topic]",
  },
  {
    title: "Idea generation",
    prompt:
      "You are the award-winning creative director of a creative ad agency. Using this brief, help me brainstorm to come up with ideas for a marketing campaign. The brief is... [copy and paste your brief]",
  },
  {
    title: "AIDA copy",
    prompt:
      "You are an expert sales and marketing professional, working for Sydney Opera House. Please help turn this campaign idea into the AIDA copywriting formula (Attention, Interest, Desire, Action) of about 100 words, suitable for giving as a presentation to the marketing team. The idea is... [copy and paste in your idea]",
  },
];
