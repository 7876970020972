import SettingsModal from "../settings/SettingsModal";
import { useModalStore } from "../../../state/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import useFetchedProfile from "../../../hooks/data/useFetchedProfile";

export default function SettingsButton() {
  const setModalChildren = useModalStore((state) => state.setChildren);
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const { profile } = useFetchedProfile();

  const handleClick = () => {
    setModalChildren(<SettingsModal />);
    setIsShowingModal(true);
  };

  // const username = instance.getActiveAccount()?.name;
  const username = profile?.email;

  return (
    <button
      onClick={handleClick}
      className="text-onSidebar flex flex-row justify-between items-center w-full p-3 mb-3 rounded-md hover:bg-lightgrey200"
    >
      {username && (
        <h1 className="text-sm text-left font-bold text-slate-800">
          {username}
        </h1>
      )}
      <FontAwesomeIcon icon={faGear} size="lg" color="rgb(30, 41, 59)" />
    </button>
  );
}
