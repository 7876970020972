import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";

export default function ImageUploadButton({
  setImage,
}: {
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Get the file
    const files = event.target.files;

    // If no file, return
    if (!files?.length) {
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // Convert the image to base64
      const base64String = reader.result as string;
      setImage(base64String);

      // Clear image
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    // Read base64 image
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <button
        onClick={() => fileInputRef.current?.click()}
        className={`bg-gray-800 hover:bg-gray-900 h-fit self-end p-2 rounded-lg`}
      >
        <FontAwesomeIcon
          color="white  "
          size="sm"
          icon={faPlus}
          width={20}
          height={15}
        />
      </button>
      <input
        onChange={handleChange}
        multiple={false}
        ref={fileInputRef}
        type="file"
        hidden
      />
    </>
  );
}
