"use client";

export default function Button({
  children,
  className,
  disabled,
  ...props
}: JSX.IntrinsicElements["button"]) {
  return (
    <button
      disabled={disabled}
      className={`${className} p-3 rounded-md bg-primary text-onPrimary hover:cursor-pointer hover:bg-hoverPrimary ${
        disabled && "bg-slate-400 hover:bg-slate-400 hover:cursor-auto"
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
