"use client";

import { useState } from "react";
import ThreadActionsButton from "./ThreadActionsButton";
import { useNavigate } from "react-router-dom";
import { tools } from "../../../constants/supportedTools";

export default function ThreadBox({
  threadId,
  threadTitle,
  threadtool,
  selected = false,
}: {
  threadId: string;
  threadTitle: string;
  threadtool?: string;
  selected?: boolean;
}) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);

  const colorStyle =
    "text-black hover:bg-lightgrey200 " +
    (selected ? "bg-lightgrey200" : "bg-transparent");

  // If this thread was used with a tool, we'll display an icon as well
  const tool = tools.find((tool) => tool.apiName === threadtool);

  return (
    <div
      className={`${colorStyle} relative px-3 py-2 gap-2 flex justify-between items-center rounded-md hover:cursor-pointer`}
      onClick={() => {
        navigate("/thread/" + threadId);
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <div className="flex flex-col items-start w-full overflow-hidden">
        <p className=" overflow-hidden whitespace-nowrap text-black font-medium pr-3">
          {threadTitle}
        </p>
        {threadtool && tool && (
          <p className="text-slate-800 text-sm">{tool.displayName}</p>
        )}
      </div>
      {(isHovering || isShowingDropdown) && (
        <ThreadActionsButton
          threadId={threadId}
          isShowingDropdown={isShowingDropdown}
          setIsShowingDropdown={setIsShowingDropdown}
        />
      )}
    </div>
  );
}
