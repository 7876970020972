import { create } from "zustand";
import { ModelRecord, models } from "../constants/supportedModels";

interface ModelState {
  model: ModelRecord | null;
  setModel: (modelValue: string) => void;
}

export const useModelStore = create<ModelState>()((set) => ({
  model: null,
  setModel: (modelValue: string) =>
    set((state) => ({ model: models.find((m) => m.model === modelValue) })),
}));
