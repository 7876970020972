import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/page";
import ThreadPage from "./pages/thread/page";
import NotFound from "./pages/notfound/page";
import { useModalStore } from "./state/modal";
import Modal from "./components/ui/Modal";
import LabPage from "./pages/lab/page";
import SignInHero from "./components/auth/SignInHero";
import UnauthenticatedContainer from "./components/auth/separators/UnauthenticatedContainer";
import AuthenticatedContainer from "./components/auth/separators/AuthenticatedContainer";

function App() {
  const isShowingModal = useModalStore((state) => state.isShowingModal);
  const setIsShowingModal = useModalStore((state) => state.setIsShowingModal);
  const children = useModalStore((state) => state.children);

  return (
    <>
      <Router>
        {/* Show the AuthButton component when the user is not authenticated. */}
        <UnauthenticatedContainer>
          <Routes>
            <Route path="/" element={<SignInHero />} />
            <Route path="*" element={<NotFound />} />{" "}
          </Routes>
        </UnauthenticatedContainer>

        {/* Show the AuthenticatedTemplate component when the user is authenticated. */}
        <AuthenticatedContainer>
          {isShowingModal && (
            <Modal closeModal={() => setIsShowingModal(false)}>
              {children}
            </Modal>
          )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/thread/:id" element={<ThreadPage />} />
            <Route path="/lab" element={<LabPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthenticatedContainer>
      </Router>
    </>
  );
}

export default App;
