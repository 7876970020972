import Button from "../../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { v4 } from "uuid";
import { protectedResources } from "../../../auth.config";
import useFetchWithMsal from "../../../hooks/useFetchWithMsal";
import { useProfileStore } from "../../../state/profile";
import { useThreadsStore } from "../../../state/threads";
import { useNavigate } from "react-router-dom";

export default function ContinueChatButton({
  userInput,
  assistantOutput,
  model,
  className,
}: {
  userInput: string;
  assistantOutput: string;
  model: string;
  provider: string;
  className?: string;
}) {
  // Server requests
  const { execute: executeCreateNewThread } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );
  const { execute: executeGenerateThreadTitle } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );
  const { execute: executeAddMessages } = useFetchWithMsal(
    {
      scopes: protectedResources.api.scopes.read,
    },
    false
  );

  // State modifiers
  const addThread = useThreadsStore((state) => state.addThread);
  const addThreadToProfile = useProfileStore(
    (state) => state.addThreadToProfile
  );
  const addMessageToThread = useThreadsStore(
    (state) => state.addMessageToThread
  );
  const setNewThreadId = useThreadsStore(
    (state) => state.newThread.setNewThreadId
  );
  const updateThreadTitle = useThreadsStore((state) => state.updateThreadTitle);
  const updateThreadTitleInProfile = useProfileStore(
    (state) => state.updateThreadTitleInProfile
  );

  const navigate = useNavigate();

  // Click Handler
  const handleClick = async () => {
    // Send request
    const response = await executeCreateNewThread(
      "POST",
      `${protectedResources.api.endpoint}/threads`,
      {
        threadTitle: "New Thread",
      }
    );
    if (!response) return;
    const createNewThreadData = await response.json();
    const threadId = createNewThreadData.threadId;
    setNewThreadId(threadId!);

    // Update state
    addThread({
      _id: threadId,
      title: "New Thread",
      messages: [],
      userUuid: "placeholder",
      lastUpdatedAt: new Date(),
      usedImageInThread: false,
    });

    addThreadToProfile(threadId, "New Thread"); // update sidebar

    // Send request to add messages
    await executeAddMessages(
      "POST",
      `${protectedResources.api.endpoint}/threads/${threadId}/messages`,
      {
        messages: [
          {
            role: "user",
            text: userInput,
            images: [],
          },
          {
            role: "assistant",
            text: assistantOutput,
            images: [],
            model: model,
            provider: model,
          },
        ],
      }
    );

    // Add user's message to state
    addMessageToThread(threadId, {
      _id: v4(),
      role: "user",
      createdAt: new Date(),
      text: userInput,
      images: [],
    });

    // Add assistants's message to state
    addMessageToThread(threadId, {
      _id: v4(),
      role: "assistant",
      createdAt: new Date(),
      text: assistantOutput,
      images: [],
      model: model,
      provider: model,
    });

    // Navigate to the new thread page
    navigate("/thread/" + threadId);

    // Generate a new title for the thread
    const titleResponse = await executeGenerateThreadTitle(
      "PATCH",
      `${protectedResources.api.endpoint}/threads/${threadId}/generate-title`
    );

    // Get response from server which is the new thread
    const data = await titleResponse?.json();

    // Progressively update thread title on client side for a cool effect
    let runningTitle = "";
    let characterDelayMs = 50;
    for (const character of data.title) {
      runningTitle += character;
      updateThreadTitle(threadId!, runningTitle);
      updateThreadTitleInProfile(threadId!, runningTitle);
      await new Promise((resolve) => setTimeout(resolve, characterDelayMs));
    }

    // Reset new thread
    setNewThreadId("");
  };

  return (
    <Button
      onClick={handleClick}
      className={"bg-white text-slate-800 w-fit " + className}
    >
      <div className="flex gap-3 items-center w-fit">
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        <p className="font-bold text-sm">Continue Chat</p>
      </div>
    </Button>
  );
}
