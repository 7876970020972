import { useEffect } from "react";
import useFetchWithMsal from "../useFetchWithMsal";
import { protectedResources } from "../../auth.config";
import { useProfileStore } from "../../state/profile";
import { Profile } from "../../schema.interfaces";

export default function useFetchedProfile(): { profile: Profile | null } {
  const { data: profileDataJson, execute } = useFetchWithMsal({
    scopes: protectedResources.api.scopes.read,
  });

  const profile = useProfileStore((state) => state.profile);
  const setProfile = useProfileStore((state) => state.setProfile);

  useEffect(() => {
    execute("GET", `${protectedResources.api.endpoint}/users/profile`);
  }, [execute]);

  useEffect(() => {
    if (profileDataJson) {
      setProfile(profileDataJson);
    }
  }, [profileDataJson, setProfile]);

  return {
    profile,
  };
}
