"use client";

import NewThreadButton from "./buttons/NewThreadButton";
import { Profile } from "../../schema.interfaces";
import ThreadDateService from "../../services/threadDate.service";
import SettingsButton from "./buttons/SettingsButton";
import LabButton from "./buttons/LabButton";
import ThreadSection from "./thread/ThreadSection";
import { useSidebarStore } from "../../state/sidebar";
import CollapsibleSidebar from "../ui/collapsible/CollapsibleSidebar";
import { ClipLoader } from "react-spinners";

export default function PrimaryBar({
  profile,
  selectedThreadId,
}: {
  profile: Profile | null;
  selectedThreadId?: string;
}) {
  const threadDateService = new ThreadDateService(profile?.threads || []);

  const todaysThreads = threadDateService.getTodaysThreads();
  const yesterdaysThreads = threadDateService.getYesterdaysThreads();
  const lastWeeksThreads = threadDateService.getLastWeeksThreads();
  const olderThreads = threadDateService.getOlderThreads();

  const userHasNoThreads = !profile?.threads || profile.threads.length === 0;

  const isShowingPrimaryBar = useSidebarStore(
    (state) => state.isShowingPrimaryBar
  );
  const hidePrimaryBar = useSidebarStore((state) => state.hidePrimaryBar);
  const showPrimaryBar = useSidebarStore((state) => state.showPrimaryBar);

  return (
    <CollapsibleSidebar
      orientation="left"
      isShowingSidebar={isShowingPrimaryBar}
      showSidebar={showPrimaryBar}
      hideSidebar={hidePrimaryBar}
    >
      <div className="pr-3">
        <img
          src="/logo-large-black.png"
          alt="Time Under Tension logo"
          className="w-1/2 mx-auto mt-4 mb-8 "
        />
      </div>

      <div className="pr-3 flex flex-row gap-3">
        <NewThreadButton />
        <LabButton />
      </div>
      {/* <div className="pr-3 mt-3">
        <ToolBox />
      </div> */}
      {profile ? (
        <>
          {userHasNoThreads && (
            <div className="text-center text-slate-800 mt-10 pr-3">
              <p className="text-lg font-semibold">No threads yet</p>
              <p className="text-base">Start a new thread to get started</p>
            </div>
          )}
          <div className="relative grow shrink overflow-hidden hover:overflow-y-scroll flex flex-col gap-2 mt-7 mb-5 pr-2 z-20">
            {ThreadSection(todaysThreads, "Today", selectedThreadId || "")}
            {ThreadSection(
              yesterdaysThreads,
              "Yesterday",
              selectedThreadId || ""
            )}
            {ThreadSection(
              lastWeeksThreads,
              "Last Week",
              selectedThreadId || ""
            )}
            {ThreadSection(olderThreads, "Older", selectedThreadId || "")}
          </div>
          <div className="pr-3">
            <SettingsButton />
          </div>
        </>
      ) : (
        <div className="relative grow shrink mr-3 ">
          <ClipLoader
            className="text-slate-800 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            loading={true}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </CollapsibleSidebar>
  );
}
