import React from "react";
import DeleteItem from "./actions/DeleteItem";
import RenameItem from "./actions/RenameItem";

export default function ThreadActionsDropdown({
  threadId,
}: {
  threadId: string;
}) {
  return (
    <div className="fixed z-30 bg-white shadow-md shadow-gray-500 rounded-md p-2">
      <DeleteItem threadId={threadId} />
      <RenameItem threadId={threadId} />
    </div>
  );
}

export function ThreadActionsDropdownItem({
  children,
  onClick,
}: {
  children?: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={`hover:bg-lightgrey200 p-3 hover:cursor-pointer flex flex-row gap-5 items-center rounded-md`}
    >
      {children}
    </div>
  );
}
