import ChatArena from "../../components/chat/containers/ChatArena";
import useFetchedProfile from "../../hooks/data/useFetchedProfile";
import LabContainer from "../../components/lab/containers/LabContainer";
import PrimaryBar from "../../components/primary-bar/PrimaryBar";
import SecondaryBar from "../../components/secondary-bar/SecondaryBar";

export default function LabPage() {
  const { profile } = useFetchedProfile();

  return (
    <>
      <main className="flex flex-row">
        <PrimaryBar selectedThreadId={""} profile={profile} />
        <ChatArena>
          <LabContainer />
        </ChatArena>
        <SecondaryBar />
      </main>
    </>
  );
}
