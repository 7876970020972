import React, { useEffect } from "react";
import CollapseSidebarButton from "./CollapseSidebarButton";

export default function CollapsibleSidebar({
  isShowingSidebar,
  showSidebar,
  hideSidebar,
  children,
  orientation,
}: {
  isShowingSidebar: boolean;
  showSidebar: () => void;
  hideSidebar: () => void;
  orientation: "left" | "right";
  children?: React.ReactNode;
}) {
  // Automatically collapse sidebar on small screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        hideSidebar();
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [hideSidebar]);

  const sideBarStyle = `w-[20rem] z-10 pr-0 h-screen flex flex-col bg-white ${
    orientation === "left" ? "border-r-2" : "border-l-2"
  } border-gray-400 ${isShowingSidebar ? "p-3 block" : "p-0 hidden"}  `;

  return (
    <div className="relative">
      <div className={sideBarStyle}>{children}</div>
      <CollapseSidebarButton
        orientation={orientation}
        isShowingSidebar={isShowingSidebar}
        showSidebar={showSidebar}
        hideSidebar={hideSidebar}
      />
    </div>
  );
}
