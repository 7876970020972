import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "c724bc97-5658-4823-bea5-ed39265536e0", // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://login.microsoftonline.com/02090d6d-8300-4255-96fc-1598ce88b07f",
    redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const protectedResources = {
  api: {
    endpoint: "https://tut-chat-server.azurewebsites.net",
    scopes: {
      read: ["api://d00fdee1-4fce-44e4-be6b-e154363674dc/Server.Admin"],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.api.scopes.read],
};
