import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModelRecord, models } from "../../constants/supportedModels";
import { useModelStore } from "../../state/model";
import { faImage } from "@fortawesome/free-solid-svg-icons";

export default function ModelGridSelection() {
  return (
    <div className="grow shrink relative ">
      <div className="absolute top-1/2 -translate-y-1/2 grid grid-cols-2 gap-5  w-full ">
        {models.map((model) => (
          <ModelGridItem key={model.model} model={model} />
        ))}
      </div>
    </div>
  );
}

function ModelGridItem({ model }: { model: ModelRecord }) {
  const setModel = useModelStore((state) => state.setModel);
  const currentModel = useModelStore((state) => state.model);

  if (!currentModel) return null;

  const isSelected = model.model === currentModel.model;

  const style = isSelected
    ? "bg-slate-800 text-white"
    : "bg-chat text-onChat hover:bg-gray-400";

  return (
    <div
      onClick={() => setModel(model.model)}
      className={`${style} border-2 border-onChat relative rounded-lg p-10 hover:cursor-pointer`}
    >
      <p className="text-center text-2xl font-bold">{model.displayModel}</p>
      <p className="text-center text-sm mt-3 font-normal">
        {model.displayProvider}
      </p>
      {model.supportsImage && (
        <FontAwesomeIcon
          className="absolute top-2 left-2"
          icon={faImage}
          size="xl"
        />
      )}
    </div>
  );
}
