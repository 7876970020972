import { useParams, useSearchParams } from "react-router-dom";
import useFetchedProfile from "../../hooks/data/useFetchedProfile";
import ChatArena from "../../components/chat/containers/ChatArena";
import ChatHeader from "../../components/chat/containers/ChatHeader";
import useFetchedThread from "../../hooks/data/useFetchedThread";
import ModelDropdownPicker from "../../components/model/ModelDropdownPicker";
import { tools } from "../../constants/supportedTools";
import ToolInfoSmall from "../../components/tools/info/ToolInfoSmall";
import ChatContainer from "../../components/chat/containers/ChatContainer";
import PrimaryBar from "../../components/primary-bar/PrimaryBar";
import SecondaryBar from "../../components/secondary-bar/SecondaryBar";

export default function ThreadPage() {
  const { id: threadId } = useParams();

  const { profile } = useFetchedProfile();
  const { thread } = useFetchedThread(threadId || "");

  // Is the user requesting a tool?
  const [searchParams] = useSearchParams();
  const toolName = searchParams.get("tool") || thread?.tool; // Get the tool from the URL query params or the thread itself

  let tool;
  if (toolName) {
    tool = tools.find((tool) => tool.apiName === toolName);
  }

  return (
    <main className="flex flex-row">
      <PrimaryBar selectedThreadId={threadId} profile={profile} />
      <ChatArena>
        <ChatHeader>
          {tool ? (
            <ToolInfoSmall tool={tool} />
          ) : (
            thread && (
              <ModelDropdownPicker
                usedImageInThread={thread?.usedImageInThread || false} // If this thread has used images, then we will not allow the user to use text-only models
              />
            )
          )}
        </ChatHeader>
        <ChatContainer thread={thread} tool={tool} />
      </ChatArena>
      <SecondaryBar />
    </main>
  );
}
