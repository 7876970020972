import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import ThreadActionsDropdown from "./ThreadActionsDropdown";
import { useEffect } from "react";

export default function ThreadActionsButton({
  threadId,
  isShowingDropdown,
  setIsShowingDropdown,
}: {
  threadId: string;
  isShowingDropdown: boolean;
  setIsShowingDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dropdownId = "THREADACTIONS";

  // If the click is outside the dropdown, close the dropdown
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target instanceof HTMLElement) {
        if (e.target.closest("#" + dropdownId)) return;
      }
      setIsShowingDropdown(false);
    });
  }, [setIsShowingDropdown]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsShowingDropdown((val) => !val);
      }}
      className="relative "
    >
      <FontAwesomeIcon icon={faEllipsis} />
      <div id={dropdownId} className={isShowingDropdown ? "" : "hidden"}>
        <ThreadActionsDropdown threadId={threadId} />
      </div>
    </div>
  );
}
