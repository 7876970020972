import { ToolRecord } from "../../../constants/supportedTools";

export default function ToolInfoLarge({ tool }: { tool: ToolRecord }) {
  return (
    <div className="relative grow text-center">
      <div className=" absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 border-2 border-slate-800 w-full p-5 py-10 rounded-lg">
        <p className="uppercase tracking-wider text-sm font-medium">Tool</p>
        <p className="font-bold text-3xl text-slate-800">{tool.displayName}</p>
        <p className="mt-3">{tool.description}</p>
      </div>
    </div>
  );
}
