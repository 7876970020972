import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ImagePreview({
  base64String,
  onCancel,
}: {
  base64String: string;
  onCancel: () => void;
}) {
  return (
    <div className="relative hover-parent-visib">
      <img
        alt="Uploaded preview"
        src={base64String}
        className="rounded-md block h-auto"
        width={150}
      />
      <ImagePreviewDeleteButton onCancel={onCancel} />
    </div>
  );
}

function ImagePreviewDeleteButton({ onCancel }: { onCancel: () => void }) {
  return (
    <div
      onClick={onCancel}
      className="hover-child-visib absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-md hover:cursor-pointer"
    >
      <FontAwesomeIcon
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        icon={faTrash}
        color="white"
        size="sm"
      />
    </div>
  );
}
