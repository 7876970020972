import Button from "../ui/Button";
import { protectedResources } from "../../auth.config";
import AuthenticatedContainer from "./separators/AuthenticatedContainer";
import UnauthenticatedContainer from "./separators/UnauthenticatedContainer";
import { useNavigate } from "react-router-dom";

export default function AuthButton({
  email,
  className,
  validateBeforeRegister,
}: {
  email?: string;
  className?: string;
  validateBeforeRegister?: () => boolean;
}) {
  const navigate = useNavigate();
  // const { instance } = useMsal();

  // const handleLoginPopup = async () => {
  //   try {
  //     await instance.loginRedirect({
  //       ...loginRequest,
  //       redirectUri: "/",
  //     });
  //     if (onLogin) {
  //       onLogin();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleLogoutPopup = () => {
  //   instance
  //     .logoutRedirect({
  //       account: instance.getActiveAccount(),
  //     })
  //     .catch((error) => console.error(error));
  // };

  const handleRegister = async () => {
    // validateBeforeRegister is a function that returns a boolean
    // It is used to prevent the user from registering with an invalid email
    if (validateBeforeRegister && !validateBeforeRegister()) {
      return;
    }

    // Send a POST request to the API to register the user
    const body = email ? JSON.stringify({ email }) : undefined;

    console.log(body);

    const response = await fetch(
      protectedResources.api.endpoint + "/users/register",
      {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { uuid } = await response.json();

    // Persist uuid in local storage
    localStorage.setItem("uuid", uuid);

    window.location.reload();
  };

  const handleLogout = async () => {
    localStorage.removeItem("uuid");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <AuthenticatedContainer>
        <Button className={className} onClick={handleLogout}>
          Sign out
        </Button>
      </AuthenticatedContainer>
      <UnauthenticatedContainer>
        <Button className={className} onClick={handleRegister}>
          Go to app
        </Button>
      </UnauthenticatedContainer>
    </>
  );
}
