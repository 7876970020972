import { Profile } from "../schema.interfaces";

type ProfileThread = Profile["threads"][0];

export default class ThreadDateService {
  threads: ProfileThread[];

  constructor(threads: ProfileThread[]) {
    this.threads = threads;
  }

  sortThreadsByDate(threads: ProfileThread[]) {
    return threads.sort((a, b) => {
      return (
        new Date(b.lastUpdatedAt).getTime() -
        new Date(a.lastUpdatedAt).getTime()
      );
    });
  }

  getTodaysThreads() {
    return this.sortThreadsByDate(
      this.threads.filter((thread) => {
        return (
          new Date(thread.lastUpdatedAt).toDateString() ===
          new Date().toDateString()
        );
      })
    );
  }

  getYesterdaysThreads() {
    return this.sortThreadsByDate(
      this.threads.filter((thread) => {
        return (
          new Date(thread.lastUpdatedAt).toDateString() ===
          new Date(Date.now() - 86400000).toDateString()
        );
      })
    );
  }

  // A function to get last week's threads excluding today and yesterday
  getLastWeeksThreads() {
    return this.sortThreadsByDate(
      this.threads.filter((thread) => {
        return (
          new Date(thread.lastUpdatedAt).getTime() <
            new Date(Date.now() - 86400000 * 2).getTime() &&
          new Date(thread.lastUpdatedAt).getTime() >
            new Date(Date.now() - 86400000 * 8).getTime()
        );
      })
    );
  }

  // A function to get threads older than last week
  getOlderThreads() {
    return this.sortThreadsByDate(
      this.threads.filter((thread) => {
        return (
          new Date(thread.lastUpdatedAt).getTime() <
          new Date(Date.now() - 86400000 * 8).getTime()
        );
      })
    );
  }
}
