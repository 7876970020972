import ModelSettingsTab from "./tabs/ModelSettingsTab";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProfileSettingsTab from "./tabs/ProfileSettingsTab";

export default function SettingsModal() {
  return (
    <>
      <div className="p-6 border-b-2 border-gray-300">
        <h2 className="font-bold text-lg">Settings</h2>
      </div>
      <div className="p-6">
        <Tabs>
          <TabList>
            <Tab>
              <p className="font-medium">Profile</p>
            </Tab>
            <Tab>
              <p className="font-medium">Model</p>
            </Tab>
          </TabList>
          <div className="mt-10">
            <TabPanel>
              <ProfileSettingsTab />
            </TabPanel>
            <TabPanel>
              <ModelSettingsTab />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </>
  );
}
