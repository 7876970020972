import { useEffect, useRef, useState } from "react";
import AuthButton from "./AuthButton";

export default function SignInHero() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const emailInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => emailInputRef.current?.focus(), []);

  const validateEmail = () => {
    setError("");
    const isValid = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!isValid) {
      setError("Please enter a valid email address before continuing.");
    }
    return !!isValid;
  };

  return (
    <main className="mx-10 md:mx-auto md:w-1/2 lg:w-1/2 xl:w-1/4 mt-32 text-center bg-black rounded-lg p-10">
      <img alt="Time Under Tension Logo" src="/logo-large.png" />
      {/* <p className="font-bold text-white mt-48 text-xl">
        Time Under Tension GPT
      </p> */}
      <div className="mt-32">
        <p className="text-white text-left mb-3">Email Address</p>
        <input
          ref={emailInputRef}
          className="w-full rounded-lg p-3 outline-none"
          placeholder="Type your email address..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <p className="text-red-400 text-left mt-3">{error}</p>}
        <AuthButton
          email={email}
          validateBeforeRegister={validateEmail}
          className="w-full mt-6  text-white border-white border-2 font-bold"
        />
      </div>
    </main>
  );
}
