import LabCardModelPicker from "../input/LabCardModelPicker";
import { ModelRecord } from "../../../constants/supportedModels";
import AssistantMessage from "../../chat/output/AssistantMessage";
import { useRef, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ContinueChatButton from "../buttons/ContinueChatButton";

export default function LabCard({
  model,
  onModelChange,
  validModelChoices,
  userInput,
  output,
  loading,
  done,
}: {
  model: ModelRecord;
  onModelChange: (model: ModelRecord) => void;
  validModelChoices: ModelRecord[];
  userInput: string;
  output: string;
  loading: boolean;
  done: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  // set new scroll height every time a new message comes in
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [output]);

  return (
    <div className="w-full flex flex-col shadow-lg shadow-gray-400 bg-white rounded-xl border-2 border-lightgrey200">
      <div className="p-3 border-b-2 border-b-lightgrey200 flex items-center justify-between px-5">
        <LabCardModelPicker
          model={model}
          onModelChange={onModelChange}
          validModelChoices={validModelChoices}
        />

        {loading ? (
          <div className="flex items-center gap-3">
            <p className="text-indigo-600 font-bold text-sm">Generating</p>
            <ClipLoader
              className="text-center align-middle"
              color="#4f46e5"
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <p className="text-green-800 font-bold text-sm">
              {done ? "Done" : "Ready"}
            </p>
            <FontAwesomeIcon icon={faCheck} color="green" size="lg" />
          </div>
        )}
      </div>

      {output && (
        <div ref={containerRef} className="px-10 py-8 overflow-y-scroll ">
          <AssistantMessage
            text={output}
            model={model.model}
            showTextOnly={true}
          />
          {done && (
            <ContinueChatButton
              className="border-2 border-slate-800 mt-10"
              userInput={userInput}
              assistantOutput={output}
              model={model.model}
              provider={model.provider}
            />
          )}
        </div>
      )}
    </div>
  );
}
